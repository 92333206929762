.grip {
    opacity: 0;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.3s;
}

.file-row:hover .grip {
    opacity: 1;
    visibility: visible;
}

.grip:hover {
    opacity: 1;
    visibility: visible;
}

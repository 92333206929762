.identeq-occupier-validation {
    display: grid; 
    grid-template-columns: 3fr 1fr; 
    gap: 25px;
}

@media print {
    .identeq-occupier-validation {
        grid-template-columns: auto;
    }
    .identeq-occupier-validation .recharts-wrapper {
        height: 700px !important;
    }
    .identeq-occupier-validation .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
}

#ov-workflow select option {
    background-color: white;
    color: black;
}

#ov-workflow select.disabled,
#ov-search select.disabled {
    background-color: #ececec;
    color: rgb(84, 84, 84);
    font-weight: normal;
}
.NHG {
    display: grid; 
    grid-template-columns: 3fr 1fr; 
    gap: 25px;

    .table.left tr td {
        text-align: center;
    }
}

.NHGVOA {
    display: grid; 
    grid-template-columns: 5fr 5fr 2fr; 
    gap: 1px;

    .table.left tr td {
        text-align: center;
    }
}

.stage-block {
    display: flex;
    gap: 10px;
    button {
        background-color: var(--primary);
        color: white;
        border: none;
        height: 5vh;
        width: 5vw;
        cursor: pointer;
    }
    button.active-stage {
        color: black;
        font-weight: bold;
    }
}

.cancel {
    background-color: var(--red);
    color: white;
    cursor: pointer;
}

.data-row {
    display: grid;
    grid-template-columns: min-content auto;
    filter: drop-shadow(0px 0px 4px #00000020);

    &.with-buttons {
        grid-template-columns: min-content auto min-content;

        button {
            padding: 5px;
        }
    }

    .data-row-title {
        width: 140px;
        font-size: 13px;
        font-weight: bold;
        background-color: var(--primary);
        padding: 4px;
        text-align: center;
        color: white;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        @media screen and (max-width: 1500px) {
            width: 60px;
            font-size: 8px;
        }
    }

    .data-row-field {
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding-left: 15px;
        min-width: 0;
    }

    .data-row-field-np {
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        min-width: 0;
    }

    .message {
        position: absolute;
        right: 10px;
        top: 5px;
        color: #ff000069;
    }

    .error-message {
        position: absolute;
        right: 35%;
        color: rgba(255,0,0,.41);
        font-weight: 700;
    }

    &.file-date {
        max-width: 400px;

        .data-row-field {
            background-color: white;
            padding-right: 10px;
            padding-left: 0px;
            select {
                width: 100%;
                height: 100%;
                border: none;
                background-color: white;
                padding-left: 10px;
            }
        }
    }
}

@media print {
    .NHG {
        grid-template-columns: auto;
    }
    .NHG .recharts-wrapper {
        height: 700px !important;
    }
    .NHG .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
}
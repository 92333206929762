.gsa-summary {
    display: grid; 
    grid-template-columns: 3fr 1fr; 
    gap: 25px;
}

.gsa {
    display: flex;
    flex-direction: column;
    gap: 10;

    .button-container {
        display: flex;
        gap: 10px;

        .button {
            font-size: 14px;
            padding: 10px;
            font-weight: 700;
        }

        .active {
            background-color: #445468;
            color: white;
        }
    }
}

.gsa-occupier-details {
    display: flex;
    gap: 10px
}

.table > tbody td button.button--notes {
  width: 35px;
  margin-right: 1rem;
}

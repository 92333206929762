.MU {
    display: grid; 
    grid-template-columns: 3fr 1fr; 
    gap: 25px;

    .table.left tr td {
        text-align: center;
    }
}

.cancel {
    background-color: var(--red);
    color: white;
    cursor: pointer;
}

.data-row {
    display: grid;
    grid-template-columns: min-content auto;
    filter: drop-shadow(0px 0px 4px #00000020);

    &.with-buttons {
        grid-template-columns: min-content auto min-content;

        button {
            padding: 5px;
        }
    }

    .search-in-line {
        cursor: pointer;
        position: absolute;
        top: 20%;
        right: 3%;
    }

    .data-row-title {
        width: 100px;
        font-size: 13px;
        font-weight: bold;
        background-color: var(--primary);
        padding: 4px;
        text-align: center;
        color: white;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        @media screen and (max-width: 1500px) {
            width: 60px;
            font-size: 8px;
        }
    }

    .data-row-field {
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding-left: 15px;
        min-width: 0;
    }

    .data-row-field-np {
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        min-width: 0;
    }

    .message {
        position: absolute;
        right: 10px;
        top: 5px;
        color: #ff000069;
    }

    .error-message {
        position: absolute;
        right: 35%;
        color: rgba(255,0,0,.41);
        font-weight: 700;
    }

    &.file-date {
        max-width: 400px;

        .data-row-field {
            background-color: white;
            padding-right: 10px;
            padding-left: 0px;
            select {
                width: 100%;
                height: 100%;
                border: none;
                background-color: white;
                padding-left: 10px;
            }
        }
    }
}

.MU-Shared {
    display: grid;
    gap: 10px;
    .summary {
        display: grid; 
        gap: 10px;

        .left-column {
            display: grid;
            .summary-grid {
                display: grid;
                grid-template-columns: 1fr 3fr 8fr 8fr;
                gap: 10px;
        
                .summary-column {
                    display: grid;
                    grid-template-rows: 1fr 1fr 1fr;
                    gap: 10px;
                }
            }
        }
    }

    .data-table {
        max-height: 60vh;
        overflow-y: auto;
        thead {
            position: sticky;
            top: 0px;
        }
        td {
            width: auto;
        }
        .centered {
            text-align: center;
            th {
                text-align: center;
            }
        }
        .category-header {
            border-left: 1px solid white;
            border-right: 1px solid white;

            div {
                display: flex;
                justify-content: space-around;
            }
        }
    }
    
}

@media print {
    .MU {
        grid-template-columns: auto;
    }
    .MU .recharts-wrapper {
        height: 700px !important;
    }
    .MU .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
}
@import 'colours';
@import 'variables';
@import 'charts';

.color-green {
    color: #06D6A0;
}

html {
    @each $name, $color in $colours {
        --#{$name}: #{$color};
    }
}

@each $name, $color in $colours {
    .colour-#{$name} {
        color: var(--#{$name});
    }

    .fill-#{$name} {
        fill: var(--#{$name});
    }

    .colour-#{$name}-outline {
        color: var(--#{$name});
        border: 1px solid var(--#{$name}) !important;
        background-color: #fff;
    }

    .background-#{$name} {
        background-color: var(--#{$name}) !important;
    }

    .outline-#{$name} {
        border: .1px solid var(--#{$name}) !important;
    }
}

body {
    background-color: var(--background);

    margin: 0;
}

* {
    font-family: 'Poppins', sans-serif;
}

.mta {
    margin-top: auto;
}

.button {
    border: none;
    border-radius: 5px;

    padding: 18px 15px 18px 15px;

    cursor: pointer;

    font-size: 18px;

    &.compact {
        padding: 0.5em 1em 0.5em 1em;
    }

    &.smaller-text {
        font-size: 15px;
    }

    &:focus, &:active {
        outline: var(--secondary) solid 2px;
        border-radius: 5px;
    }
}

.bookmark-button {
    cursor: pointer;
    margin-left: 5px;
}

.input {
    padding: 18px 9px 18px 9px;

    &.slim {
        padding: 9px 4px 9px 4px;
    }

    font-size: 18px;

    border-radius: 5px;

    border: 1px solid #E2E2E2;
    &.readonly {
        border: none;
    }
}

.grid {
    display: grid;

    &-auto-columns {
        grid-auto-columns: 1fr;
    }

    @for $i from 0 through 100 {
        &-gap-#{$i} {
            gap: #{$i}px;

            @media only screen and (max-width: $tablet-breakpoint) {
                margin-bottom: #{$i}px;

            }
        }
    }

    @for $i from 0 through 12 {
        &-columns-#{$i} {
            grid-template-columns: repeat(#{$i}, 1fr);

            @media only screen and (max-width: $tablet-breakpoint) {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.margin-top-25 {
    margin-top: 25px;
}

.center {
    justify-content: center;
}

.middle {
    align-items: center;
}

.flex {
    display: flex;

    &-column {
        display: flex;

        flex-direction: column;
    }
}

.font {
    @for $i from 0 through 9 {
        &-weight-#{$i*100} {
            font-weight: #{$i*100};
        }
    }

    @for $i from 0 through 4 {
        &-size-#{$i} {
            font-size: #{$i}em;
        }
    }

    &-align {
        &-left { text-align: left; }
        &-center { text-align: center; }
        &-right { text-align: right; }
    }
}

.no {
    &-margin {
        > * {
            margin: 0;
        }
    }
}

.table {
    &.left {
        tr {
            td, th {
                text-align: left;
            }
        }
    }
    &.smaller-text {
        tr {
            td, th {
                font-size: 14px;
            }
        }
    }
    &.super-small-text {
        tr {
            td, th {
                font-size: 10px;
            }
        }
    }

    &.squish {
        td, th {
            padding: 0.5em;
        }

        th {
            font-size: 14px;
        }
    }

    &.extra-row-padding {
        td {
            padding: 10px;
        }
    }

    border-spacing: 0;
    border-collapse: collapse;

    color: var(--white);

    width: 100%;

    > thead {
        background: var(--primary);

        > tr > th {
            padding: 1em;

            &.low {
                padding: .5em;
            }
        }

        > tr:first-of-type > th:first-of-type {
            border-top-left-radius: 5px;
        }

        > tr:first-of-type > th:last-of-type {
            border-top-right-radius: 5px;
        }
    }

    > tbody {
        background: var(--white);

        color: var(--secondary);

        > tr > td {
            padding: 1em;

            text-align: center;
        }

        >tr:nth-of-type(even) {
            background: var(--light-gray);
        }

        td {
            input,button {
                width: 100%;
            }
        }

        .actions {
            a, span {
                color: var(--primary);
                cursor: pointer;
            }
        }
    }

    &.borders {
        tr {
            td {
                border:1px solid #e8e8e8;
                &:first-child {
                    border-left: none;
                }

                &:last-child {
                    border-right: none;
                }
            }

            &:first-child {
                td {
                    border-top: none;
                }
            }

            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}

.error {
    color: var(--red);
}

.form-row {
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 10px;
    }

    .permissions label {
        margin: 0px;
    }
}

.ReactModal__Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    .ReactModal__Content {
        min-width: 400px;
        &:focus {
            outline: none;
        }

        h2 {
            color: var(--secondary);
            text-align: center;
        }

        .dropzone {
            display: flex;
            width: 400px;
            background-color: #f7f7f7;
            height: 150px;
            padding: 20px;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
        }

        .modal-message {
            text-align: center;
            min-height: 25px;
        }
    }
}

.text-center {
    text-align: center;
}

.page-wrapper {
    grid-template-rows: min-content auto min-content;
    min-height: 100vh;
}

.grid-portal {
    @media screen and (max-width: 1800px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (max-width: 1350px) {
        grid-template-columns: 1fr 1fr;
    }
}

.delete {
    &.enabled {
        .button {
            background-color: red;
        }
        .fa-unlock {
            display: inline;
        }
        .fa-lock {
            display: none;
        }
    }

    &.disabled {
        .fa-unlock {
            display: none;
        }
        .fa-lock {
            display: inline;
        }
    }
}

.opacity-0 {
    opacity: 0;
}

.typo-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
}

@for $i from 0 through 12 {
    .items-#{$i} {
        grid-template-columns: repeat($i, 1fr);
        button {
            padding-top: 5px;
            padding-bottom: 5px;
            border-radius: 0;
            background-color: white;
            border: 1px solid var(--primary);
            border-right: none;
            &:last-of-type {
                border: 1px solid var(--primary);
            }
            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            &.active {
                background-color: var(--primary);
                color: white;
            }
        }

        &.items-#{$i} {
            grid-template-columns: repeat($i, 1fr);
        }
    }
}

.font-14 {
    font-size: 14px !important;
}

.width-30 {
    width: 40px;
}

.navigate {
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: var(--white);
    text-decoration: none;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;

    cursor: pointer;
}

.download {
    max-height: 67vh;
    min-height: 15vh;
    overflow-y: auto;
    text-align: center;
}

.map {
    height: 100%;
    background-color: grey;
}

.data-row.display-type {
    filter: drop-shadow(0px 0px 4px #00000020);
    display: grid;
    grid-template-columns: auto min-content;
}

.display-type {
    p {
        cursor: pointer;
        margin: 0;
        color: var(--primary);
        padding-left: 15px;
    }
}

.batch-product-config {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file-remove {
    position: absolute; 
    padding: 15px; 
    cursor: pointer;
}

.file-remove:hover {
    color: var(--red);
}

@media print {

    html, body {
        height: 99.9%;    
    }

    .table.borders tr td {
        padding: 1px;
    }

    .table {
        &.squish {
            td, th {
                padding: 0px;
            }
    
            th {
                font-size: 10px;
            }
        }

        &.smaller-text {
            tr {
                td, th {
                    font-size: 10px;
                }
            }
        }
        &.super-small-text {
            tr {
                td, th {
                    font-size: 10px;
                }
            }
        }
    }

    .wrapper .container .data-row .data-row-field {
        height: auto;
        min-height: -webkit-fill-available;
    }

    .wrapper .container .data-row .data-row-title {
        padding: 0px;
        width: 130px;
        font-size: 10px;
    }
    
    .wrapper .container .data-row .message {
        right: 30px;
    }

    .wrapper .container .kpi-card.grid.center.middle {
        padding: 0px;
        .font-align-center {
            font-size: 0.75em;
        }
    }

    .button.compact.smaller-text {
        display: none;
    }

    .grid-overview .left {
        grid-gap: 0px;
        gap: 5px;
        height: 10px;
    }
    
    .recharts-surface {
        height: 600px;
    }

    .recharts-wrapper {
        height: 600px !important;
        margin: 0 auto !important;
    }

    #contact-validation-page hr {
        display: none;
    }

    .table-format {
        margin-bottom: 0px;
        margin-top: -10px;
    }

    .recharts-legend-wrapper {
        margin-bottom: 0px;
    }

    .navigate {
        display: none;
    }

    .using-graph {
        min-height: 600px !important;
        max-height: 900px !important;
        border-radius: 0px !important;
    }

    .left .using-graph {
        min-height: 600px !important;
        max-height: 900px !important;
    }

    .grid-overview .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
    
    .recharts-default-legend .recharts-surface {
        height: 100px;
    }
    
}

.tr-drag-bars:hover .drag-bars,
.tr-drag-bars .drag-bars:hover {
    opacity: 1;
}
.drag-bars {
    opacity: 0;
    position: absolute;
    display: flex;
    padding: 0px 0px 0px 1em;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
}
.drag-bars i {
    color: #809fac;
    cursor: pointer;
}
.drag-bars i:hover {
    color: unset;
}

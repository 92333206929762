.twilio-call-block {
    border: black solid 1px;
    text-align: center;
    overflow-y: auto;
    select {
        width: 100%;
        justify-self: center;
    }
    input {
        width: 100%;
        justify-self: center;
        padding: 1px 0px 1px 0px;
    }
}

.twilio-call-block > div {
    margin: 0px 10px 10px 10px
}

.number-container {
    display: grid;
    .close-twilio {
        position: absolute;
        margin-top: 10px;
        cursor: pointer;
    }

    .open-twillio {
        margin-top: 10px;
        cursor: pointer;
    }
}

.dial-pad {
    border: solid 1px black;
    padding: 1%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .number {
        background-color: var(--primary);
        color: white;
        border: solid 1px var(--primary);
        cursor: pointer;
        padding: 15%;
        margin: 2%;   
    }

    .number:hover {
        filter: brightness(1.1);    
    }
}

.dial-pad-number {
    font-weight: bold;
    font-family: Poppins, sans-serif;
    width: 11ch;
    max-width: max-content;
    font-size: inherit;
}
.filter-content {
  background-color: white;

  padding: 15px;
  
  &:nth-last-child(2) {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  }

  .filter-row {
      display: grid;
      gap: 5px;
      .filter-row-title {
          font-weight: bold;
      }
      .filter-row-data {
          display: grid;

          @for $i from 0 through 12 {
              &.items-#{$i} {
                  grid-template-columns: repeat($i, 1fr);
                  button {
                      padding-top: 5px;
                      padding-bottom: 5px;
                      border-radius: 0;
                      background-color: white;
                      border: 1px solid var(--primary);
                      border-right: none;
                      &:last-of-type {
                          border: 1px solid var(--primary);
                      }
                      &:first-child {
                          border-top-left-radius: 5px;
                          border-bottom-left-radius: 5px;
                      }

                      &:last-child {
                          border-top-right-radius: 5px;
                          border-bottom-right-radius: 5px;
                      }

                      &.active {
                          background-color: var(--primary);
                          color: white;
                      }
                  }

                  &.items-#{$i} {
                      grid-template-columns: repeat($i, 1fr);
                  }
              }
          }
          
      }
  }
}